<template>
  <!-- TODO requires new api calls for the Helpdesk, as relies on teacher
calls which are out of scope  -->
  <div class="content-container">
    <PageTitle :title="$t('MY_CLASSES.TITLE')" class="page-title">
      <template #extra-content>
        <ActionButton :icon="require('../../../assets/icons/icn_delete.svg')" class="delete-button" :text="$t('CLASSES_HELPDESK.DELETE_CLASS')" @click="() => deleteClassWarning()" />
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('EDIT_CLASS.TITLE') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_HELPDESK.CLASS_DETAILS" :params="{ classId: classId }" />

    <!-- Form -->
    <FormWrapper v-if="classDetails"
      :send-form-data="validateData"
      class="page-form"
      :on-form-data-update="updatedForm">
      <template #form-content>

        <!-- Name -->
        <InputClassName :original-name="classDetails.name"
                        :on-input="nameUpdated"
                        :accepted="(value) => nameAvailable = value" />

        <div class="two-column-wrapper form-element">
          <!-- TODO: Number of Lessons & Accessability Type -->
          <!-- Total Students -->
          <InputField ref="studentCountInput"
                      :field-title="$t('TOTAL_STUDENTS')"
                      :field-name="'studentCount'"
                      :rules="'required'"
                      :min="0"
                      :max="40"
                      :type="'number'"
                      :value="classDetails.studentCount"
                      :bind-value="true"
                      :placeholder="$t('TOTAL_STUDENTS')"
                      :cy-selector="'students-input'"
                      :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                      :api-error-text="''"
                      class="form-element-column "/>

          <!-- Accessability Type -->
          <Dropdown id="accessibilityType"
                    :field-title="$t('EDIT_CLASS.ASSISTANCE')"
                    :dropdown-options="accessibilityOptions"
                    :placeholder="$t('ASSISTANCE.REGULAR')"
                    :field-name="'accessibilityType'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :callback="accessibilityTypeUpdate"
                    :selected-option="selectedAccessibilityType"
                    :api-error-text="''"
                    :input-icon="require('../../../assets/icons/icn_gebarentaal.svg')"
                    class="form-element-column"/>
        </div>

        <!-- Sound settings-->
        <div class="toggles-container">
          <ToggleSwitch :field-title="'Extra geluiden in de MediaMissies'"
                        :field-name="'hasExtraSounds'"
                        :value="hasExtraSounds"
                        :callback="onSoundsToggle"/>
        </div>

        <!-- Competition type -->
        <div class="field-wrapper">
          <Dropdown id="group"
                    :field-title="$t('EDIT_CLASS.COMPETITION_TYPE')"
                    :dropdown-options="groups"
                    :placeholder="$t('EDIT_CLASS.SELECT_COMPETITION_TYPE')"
                    :field-name="'classTypeName'"
                    :selected-option="selectedGroup"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :callback="() => {}"
                    :api-error-text="difficultyApiError ? $t(difficultyApiError) : ''"
                    @selected="selectedGroup = $event"
                    class="form-element"/>
          <div class="info-button-wrapper" @click="isCompetitionInfoShown = !isCompetitionInfoShown">
            <img src="@/assets/icons/icn_info.svg" alt="info" class="info-button">
          </div>
          <!-- Info popup -->
          <div class="info-popup" :class="{'shown': isCompetitionInfoShown}">
            <p class="info-popup-text">{{ $t('EDIT_CLASS.COMPETITION_INFO') }}</p>
            <div class="triangle" />
          </div>
        </div>

        <!-- Teacher -->
        <DropdownWithCheckboxes id="teachers"
                                ref="teacherInput"
                                :field-title="$t('EDIT_CLASS.TEACHERS')"
                                :dropdown-options="allTeachers"
                                :placeholder="$t('EDIT_CLASS.TEACHERS_PLACEHOLDER')"
                                :field-name="'teacherNames'"
                                :selected-options="originalTeachers"
                                :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                                :callback="onTeacherSelect"
                                :api-error-text="''"
                                class="form-element"/>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('SAVE_CHANGES') }}
        </button>
      </template>

    </FormWrapper>

    <!-- "Save changes?" Warning -->
    <DeleteClassWarning v-if="activeBasicModal === MODAL_NAMES.DELETE_CLASS" ref="deleteWarningModal"/>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { GET_CLASS_DETAILS, EDIT_CLASS_DETAILS, GET_SCHOOL_DETAILS } from '@/store/modules/helpdesk/actions'
import { GET_CLASS_TYPES_HELPDESK } from '@/store/actions'
import PageTitle from '@/components/elements/texts/PageTitle'
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import DropdownWithCheckboxes from '@/components/yo-form/DropdownWithCheckboxes'
import { RESET_BASIC_MODAL_DATA, SET_BASIC_MODAL_DATA, SET_TOAST_DATA } from '@/store/mutations'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import DeleteClassWarning from '@/components/elements/helpdeskClasses/DeleteClassWarning'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import ActionButton from '@/components/table/ActionButton'
import ApiHandler from '@/utils/handlers/ApiHandler'
import InputClassName from '@/components/elements/inputFields/InputClassName'
import { useI18n } from 'vue-i18n'
import Dropdown from '@/components/yo-form/Dropdown'
import ToggleSwitch from '@/components/yo-form/ToggleSwitch'

export default {
  name: 'ClassEdit',
  components: {
    DeleteClassWarning,
    DropdownWithCheckboxes,
    PageTitle,
    FormWrapper,
    InputField,
    BackButton,
    ActionButton,
    InputClassName,
    Dropdown,
    ToggleSwitch
  },
  setup(props) {
    const { t } = useI18n({})
    const route = useRoute()
    const router = useRouter()    
    const store = useStore();
    store.dispatch(GET_CLASS_TYPES_HELPDESK);

    const classId = route.params.classId
    const classDetails = computed(() => store.getters.getClassDetails)
    const activeBasicModal = computed(() => store.getters.getActiveBasicModal)
    const allTeachers = ref([])
    const originalTeachers = computed(() => classDetails.value.linkedTeachers)
    const selectedTeachers = ref([])
    const selectedGroup = ref({})
    const isSpecialSchool = ref(false)
    const isCompetitionInfoShown = ref(false)
    const difficultyApiError = ref('')

    const groups = computed(() => {
      const classTypes = store.getters.getClassTypes;
      selectedGroup.value = classTypes?.find(type => type.id === classDetails.value.classTypeId);
      if(isSpecialSchool.value) {
        return classTypes.filter(type => type.isSpecialEducationEligible);
      }
      return classTypes;
    });


    const currentName = ref('')
    const nameAvailable = ref(true)
    function nameUpdated(value) {
      currentName.value = value
    }

    /** Accessability Type **/
    const accessibilityOptions = [
      { id: 1, name: t('ASSISTANCE.REGULAR') },
      { id: 2, name: t('ASSISTANCE.SIGN_LANGUAGE') },
      { id: 3, name: t('ASSISTANCE.AUDIO_DESCRIPTION') },
      { id: 4, name: t('ASSISTANCE.SUBTITLES') }]
    const selectedAccessibilityType = ref(accessibilityOptions[0])

    function accessibilityTypeUpdate(selected) {
      selectedAccessibilityType.value = selected
    }

    const hasExtraSounds = ref(true)
    function onSoundsToggle(newToggleVal) {
      hasExtraSounds.value = newToggleVal
    }

    watch(() => classDetails.value,
      (curr, prev) => {
        if (curr.schoolId) {
          store.dispatch(GET_SCHOOL_DETAILS, curr.schoolId).then(response => {
            isSpecialSchool.value = response.data.isSpecialSchool
            allTeachers.value = response.data.linkedTeachers
          })
        }
        selectedAccessibilityType.value = curr.accessibilityType
        hasExtraSounds.value = curr.hasExtraSounds === 1
      })

    store.dispatch(GET_CLASS_DETAILS, classId)

    watch(() => originalTeachers.value, (curr, prev) => {
      selectedTeachers.value = curr
    })

    function onTeacherSelect(selected) {
      selectedTeachers.value = selected
    }

    function updatedForm() {
      difficultyApiError.value = '';
    }


    function validateData(data) {
      if (!nameAvailable.value) {
        return
      }
      data.id = classId
      data.teachers = selectedTeachers.value.map(value => value.id)
      data.accessibilityTypeId = selectedAccessibilityType.value.id
      data.classTypeId = selectedGroup.value.id
      editClass(data)
    }

    function editClass(data) {

      store.dispatch(EDIT_CLASS_DETAILS, data).then((response) => {
        if (response.status === 409) {
          return // name not available
        }
        if (response.status === 400) {
          if (response.data?.error === 'cannot_change_class_difficulty_with_points') {
            difficultyApiError.value = 'EDIT_CLASS.EDIT_CLASS_ERROR'
          }
          return
        }
        store.commit(SET_TOAST_DATA, { text: `Schoolklas "${response.data.name}" succesvol aangepast`, src: require('@/assets/icons/icn_successful.svg') })
        router.push({ name: ROUTE_NAMES_HELPDESK.CLASS_DETAILS, params: { classId: classId } })
      })
    }

    /** Delete **/
    const deleteWarningModal = ref(null) // The ref of the Vue component so we can access it.

    function deleteClassWarning() {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.DELETE_CLASS(classDetails.value.name),
          cancelCallback: null,
          executeCallback: onClassDeletion
        },
        activeModal: MODAL_NAMES.DELETE_CLASS
      }
      // After successful validation, the user should see a warning popup.
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function onClassDeletion() {
      // Check if the checkboxes have been all clicked. If they are not, an error is automatically displayed.
      const areAllCheckboxesChecked = deleteWarningModal.value.validateCheckboxes()
      if (areAllCheckboxesChecked) {
        deleteClass(classId)
        store.commit(RESET_BASIC_MODAL_DATA)
      }
    }

    function deleteClass(classId) {
      return ApiHandler.delete(`helpdesk/school-classes/delete-school-class/${classId}`)
        .then(() => {
          router.push({ name: ROUTE_NAMES_HELPDESK.CLASSES_OVERVIEW })
          store.commit(SET_TOAST_DATA, { text: 'Klas succesvol verwijderd', src: require('../../../assets/icons/icn_successful.svg') })
        })
    }


    return {
      deleteClassWarning,
      classDetails,
      ROUTE_NAMES_HELPDESK,
      validateData,
      updatedForm,
      classId,
      onTeacherSelect,

      /** Accessibility Type **/
      accessibilityOptions,
      selectedAccessibilityType,
      accessibilityTypeUpdate,

      /** Group */
      selectedGroup,
      groups,
      isSpecialSchool,
      isCompetitionInfoShown,
      difficultyApiError,

      MODAL_NAMES,
      /** Delete **/
      deleteWarningModal,
      activeBasicModal,
      originalTeachers,
      allTeachers,
      nameUpdated,
      nameAvailable,

      hasExtraSounds,
      onSoundsToggle
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";


.half-width{
  width: 50%;
}

.toggles-container {
  margin-bottom: rem(32);
}

.divider {
  background-color: var(--purple_light);
  border: none;
  height: rem(2);
  opacity: 30%;
  margin: rem(40) 0;
}

.info-button-wrapper {
  position: absolute;
  right: rem(-35);
  top: rem(52);
  transform: translateY(-50%);
  cursor: pointer;
}

.field-wrapper {
  position: relative;
}

.info-popup {
  position: absolute;
  top: rem(-22);
  right: rem(-440);
  width: rem(380);
  padding: rem(16);
  border-radius: rem(8);
  background: white;
  color: var(--blue_dark-03);
  box-shadow: rem(2) rem(2) 0 0 var(--gray-light_04);
  opacity: 0;
  transition: opacity 0.2s;

  &.shown {
    opacity: 1;
  }
}

.triangle {
  position: absolute;
  left: rem(-14);
  top: 50%;
  height: 0;
  border-left: rem(8) solid transparent;
  border-right: rem(8) solid transparent;
  border-bottom: rem(16) solid white;
  transform: rotate(-90deg) translateX(50%);
}
</style>
