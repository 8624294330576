<template>
  <BasicModal>
    <template #content>
      <div class="delete-confirmations">
        <p class="delete-text">{{ $t('ACTION_NOT_UNDONE') }}</p>

        <BasicCheckbox :id="'progressLost'"
                       :label-text="$t('DELETE_CLASS_WARNING.PROGRESS_LOST')"
                       custom-class="small-gray"
                       :callback="onCheckboxChange"/>
      </div>

      <p v-if="deleteError" class="text-error">You need to check all checkboxes.</p>
    </template>
  </BasicModal>
</template>

<script>
import { ref } from '@vue/reactivity'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import BasicCheckbox from '@/components/elements/inputFields/BasicCheckbox'

export default {
  name: 'DeleteClassWarning',
  components: { BasicCheckbox, BasicModal },
  setup() {
    const deleteError = ref(false)
    const confirmations = ref({ progressLost: false }) // We keep the object in case we add more checkbox.
    const checkboxesCount = Object.keys(confirmations.value).length

    function onCheckboxChange(id, checkboxData, checkboxValue) {
      confirmations.value[id] = checkboxValue
    }

    function validateCheckboxes() {
      let allConfirmed = 0

      // We must check if all 3 checkboxes are checked.
      Object.keys(confirmations.value).map(key => {
        if (confirmations.value[key]) {
          allConfirmed++
        }
      })

      // If all the checkboxes are not checked, an error should be displayed.
      deleteError.value = allConfirmed < checkboxesCount

      // Return if the checkboxes are all checked.
      // true -> there is an error, false -> error-free
      return !deleteError.value
    }

    return {
      validateCheckboxes,
      onCheckboxChange,
      deleteError
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.delete-confirmations {
  margin: 0 auto;
  width: 80%;
}

.delete-text {
  font-weight: 600;
  margin-bottom: rem(16);
  margin-top: rem(27);
}

.text-error {
  text-align: center;
}
</style>
